import React from "react";

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h1>PORTFOLIO COMING SOON</h1>
    </div>
  );
};

export default Portfolio;
