import React from "react";
import { NavLink } from "react-router-dom";
import signature from "../medias/signature.svg";

const Footer = () => {
  return (
    <footer>
      <div className="links">
        <NavLink to="/partners">Partners</NavLink>
        <NavLink to="/portfolio">Portfolio</NavLink>
        <a href="mailto:contact@platypusws.com">Contact@platypusws.com</a>
      </div>
      <div className="copyright">
        {" "}
        2019 Platypusws.com Developed & Designed By{" "}
        <img src={signature} alt="" />
      </div>
      <div className="extra"></div>
    </footer>
  );
};

export default Footer;
