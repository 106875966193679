import React from "react";

const Partners = () => {
  return (
    <div className="partners">
      <h1>Partners Coming Soon</h1>
    </div>
  );
};

export default Partners;
