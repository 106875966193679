import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import loader from "../medias/loading.gif";

const Post = () => {
  let posturl = `${process.env.REACT_APP_API_URL}/posts/${useParams().id}`;

  const { data, isLoading } = useQuery(["platypusblog"], () => {
    return Axios.get(posturl).then((res) => res.data);
  });

  console.log(data);

  return (
    <>
      {isLoading ? (
        <img src={loader} alt="" className="loader" />
      ) : (
        <div className="singlepost">
          <img src={data?.fimg_url} alt="" className="featuredpostimg" />
          <h1 className="post_heading">{data?.title.rendered}</h1>
          <p className="post_content">{data?.content.rendered}</p>
        </div>
      )}
    </>
  );
};

export default Post;
