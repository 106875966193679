import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Contact = () => {
  const form = useRef();
  const notify = () => toast("Form Submitted Successfully!");

  let submithandler = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_g3rd0ho",
        "template_jg7nofm",
        form.current,
        process.env.REACT_APP_EMAILJS_API
      )
      .then(
        (result) => {
          notify();
        },
        (error) => {
          alert(error);
        }
      );
  };

  return (
    <div className="contact">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <h1>Get In Touch!</h1>
      <form ref={form} onSubmit={submithandler}>
        <input type="text" name="user_name" placeholder="Your Name" required />

        <input
          type="email"
          name="user_email"
          placeholder="Your Email"
          required
        />

        <textarea rows="6" name="message" placeholder="Your Message" />

        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default Contact;
