import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../medias/logo.svg";

const Navbar = () => {
  return (
    <nav>
      <div className="logo">
        <img src={logo} alt="" /> <span>PLATYPUS WEB - SOLUTIONS</span>
      </div>
      <div className="links">
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <NavLink to="/portfolio">Portfolio</NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
