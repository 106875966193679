import React from "react";
import Footer from "../components/footer";
import bg from "../medias/bg.mp4";

const Home = () => {
  return (
    <section className="home">
      <video autoPlay loop muted>
        <source src={bg} type="video/mp4" />
      </video>
      <h1>
        WE
        <br />
        CRAFT
        <br />
        WEB-ASSETS
      </h1>
    </section>
  );
};

export default Home;
