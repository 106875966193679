import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import loader from "../medias/loading.gif";
import { useQuery } from "@tanstack/react-query";

const Blog = () => {
  let postsurl = `${process.env.REACT_APP_API_URL}/posts`;

  const { data, isLoading } = useQuery(["platypusblogs"], () => {
    return Axios.get(postsurl).then((res) => res.data);
  });

  return (
    <>
      {isLoading ? (
        <img src={loader} alt="" className="loader" />
      ) : (
        <div className="blogposts">
          {data.map((item) => {
            return (
              <div className="post" key={item.id}>
                <h1>{item.title.rendered}</h1>
                <img src={item.fimg_url} alt="" />
                <p
                  className="excerpt"
                  dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }}
                ></p>
                <Link className="readmore" to={`/blog/${item.id}`}>
                  READ MORE
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Blog;
